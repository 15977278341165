/**
 * @packageDocumentation
 * @module @ariestech/atr-core-i18n
 */
import { ActionType, createReducer } from 'typesafe-actions';
import actions from '../actions';

type ActionTypes = ActionType<typeof actions>;

export type State = {
  initialized: boolean;
  currentLanguage: string;
};

const defaultState: State = {
  initialized: false,
  currentLanguage: '',
};

const reducer = createReducer<State, ActionTypes>(defaultState)
  .handleAction(actions.changeLanguageRequest, (state, action) => {
    return { ...state, currentLanguage: action.payload };
  })
  .handleAction(actions.initializeTranslationsSuccess, (state, action) => {
    return { ...state, initialized: true, currentLanguage: action.payload };
  });
export default reducer;
