import { batch } from 'react-redux';
import * as types from '@ariestech/x-types';
import {
  AnyAction,
  applyMiddleware,
  createStore,
  Store,
  ReducersMapObject,
  combineReducers,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware, { EffectMiddleware } from 'redux-saga';
import {
  PersistedState,
  Persistor,
  persistReducer,
  persistStore,
  REHYDRATE,
} from 'redux-persist';
import rootSaga from './sagas';
import { persistConfig, setPersistor } from './persistence';
import * as sagaInjector from './injectSaga';
import staticReducersMap from './reducers';
// import { trackingMiddleware } from "../configuration/tracking";

const effectMiddleware = (next => effect => {
  if (effect) {
    batch(() => next(effect));
    return;
  }

  next(effect);
}) as EffectMiddleware;

export function createRootReducer(allReducers: ReducersMapObject<any, any>) {
  const persistedReducer = persistReducer(
    persistConfig,
    combineReducers(allReducers),
  );

  /**
   * High level reducer to intercept initial REHYDRATE action and ignore if the user is not logged
   */
  function rootReducer(
    state: object & types.State & PersistedState,
    action: AnyAction,
  ) {
    if (action?.type === REHYDRATE) {
      // TODO improve user identification and use selector
      return state;
    }
    return persistedReducer(state, action);
  }

  return rootReducer;
}

function configureStore(): { store: Store<types.State>; persistor: Persistor } {
  const sagaMiddleware = createSagaMiddleware({
    effectMiddlewares: [effectMiddleware],
  });

  // set runner for lazy loaded sagas
  sagaInjector.setSagaRunner(sagaMiddleware.run);

  const rootReducer = createRootReducer(staticReducersMap);
  // Keep this middleware as the first one because it filters actions that requires online when no internet createOfflineMiddleware
  const middlewares = [sagaMiddleware];
  // Should use line below if you add trackingMiddleware
  // const middlewares = [sagaMiddleware, trackingMiddleware];

  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  const persistor = persistStore(store);
  persistor.pause();
  setPersistor(persistor);
  const sagas = [rootSaga];
  sagas.forEach(saga => {
    sagaMiddleware.run(saga);
  });

  return { store: (store as unknown) as Store<types.State>, persistor };
}

export default configureStore;
