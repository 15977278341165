import React, { useState, useRef } from 'react';
import { useTranslations } from '../../../language/hooks';
import { Flex, Typography, Asset, Box } from '@ariestech/atr-core-web-ui';
import { useWindowSize } from 'src/_shared/hooks';
import Lottie from 'lottie-react-web';
import cart from '../../../_shared/assets/lottie/cart.json';
import { useEcommerceContext } from './../../../ecommerce/hooks/useEcommerceContext';
import { NavigationBar } from 'src/_shared/components/NavigationBar/NavigationBar';
import { useNavigateVideo } from 'src/videoplayer/hooks';

export const MainInteraction = () => {
  console.log('MAIN');
  const t = useTranslations();
  const [width, height] = useWindowSize();
  const [cartPlay, setCartPlay] = useState(false);
  const [cartStopped, setCartstopped] = useState(false);
  const { ecommctx } = useEcommerceContext();
  ecommctx.iconcartRef = useRef(null);
  const lottie = ecommctx.iconcartRef;
  const { currentVideo, useNavigateVideoRequest } = useNavigateVideo();
  const { execute } = useNavigateVideoRequest();

  return (
    <Flex id='cover' width={width} height={height}>
      <NavigationBar></NavigationBar>
      <div
        style={{
          position: 'absolute',
          top: 10,
          right: 30,
          width: 150,
          height: 150,
          zIndex: 1000,
          pointerEvents: 'all',
          cursor: 'pointer',
          display: 'none',
        }}
        onClick={() => {
          setCartstopped(false);
          setCartPlay(true);
        }}
      >
        <Lottie
          ref={lottie}
          options={{
            animationData: cart,
            loop: false,
            autoplay: false,
          }}
          isPaused={!cartPlay}
          isStopped={cartStopped}
          eventListeners={[
            {
              eventName: 'complete',
              callback: () => {
                setCartstopped(true);
              },
            },
          ]}
        />
      </div>
    </Flex>
  );
};
