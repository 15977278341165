import { all, fork } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getVideosSaga } from './fetch';
import {getNavigationSaga} from './navigation';

const allSagas = [getVideosSaga, getNavigationSaga];

export function* rootSaga(): SagaIterator {
  yield all(allSagas.map(s => fork(s)));
}
