import { colors } from '../colors';

export const checkbox = {
  display: 'inline-block',
  width: 20,
  height: 20,
  bg: 'primary',
  borderRadius: 3,
  transition: 'all 150ms',
  states: {
    // '&:focus-within': {
    //   boxShadow: `0 0 0 3px ${colors.positive}`,
    // },
    '&:hover': {
      boxShadow: `0 0 0 3px ${colors.primary}`,
    },
  },
  textStyle: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  variants: {
    checked: {
      bg: 'dark',
      states: {
        '&:hover': {
          boxShadow: `0 0 0 3px ${colors.dark}`,
        },
      },
    },
    disabled: {
      pointerEvents: 'none',
      opacity: 0.4,
    },
  },
};
