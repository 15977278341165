import { createGeneralHook } from '@ariestech/atr-core-requests-status-management';
import { changeLanguage } from '../actions/actions';

export const useChangeLanguage = () => {
  const useChangeLanguageRequest = createGeneralHook(
    'changelanguage',
    (lang: string) => changeLanguage(lang),
  );

  return { useChangeLanguageRequest };
};
