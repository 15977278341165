/**
 * @packageDocumentation
 * @module @ariestech/atr-core-i18n
 */
import I18n, { Scope, TranslateOptions } from 'i18n-js';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import selectors from '../selectors';
import { Priority } from '../types';
import { config } from '../config';

const translateFunc = I18n.t;

/**
 * Returns a translation function that can be used get translation from key.
 * It Auto rerenders on language changes.
 * if currentPriority is locale, return only I18n method without check from selector
 */

function useTranslationsRemote(): (
  scope: Scope,
  options?: TranslateOptions,
) => string {
  const currentLanguage = useSelector(selectors.selectCurrentLanguage);
  return useCallback(
    (scope: Scope, options?: TranslateOptions) => {
      return translateFunc(scope, {
        locale: currentLanguage,
        ...(options || {}),
      });
    },
    [currentLanguage],
  );
}

function useTranslations(): (
  scope: Scope,
  options?: TranslateOptions,
) => string {
  return translateFunc;
  /*   return config.priority === Priority.Remote
    ? useTranslationsRemote()
    : translateFunc;*/
}

export default useTranslations;
