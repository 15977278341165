import React, { useEffect, useState } from 'react';
import { EnterPassword } from '../Enterpassword/Enterpassword';

export const Calpurnia = (): JSX.Element => {
  return (
    <div
      className={'radialbackground'}
      style={{
        width: '60%',
        height: '80%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'start',
          alignItems: 'start',
        }}
      >
        <EnterPassword
          password={'CALPURNIA'}
          navigateto={'portone'}
          hint={'Inserisci la parola chiave per proseguire'}
        />
      </div>
    </div>
  );
};
