import { all, call, spawn } from 'redux-saga/effects';
import { sagas as i18nSaga } from '../../language';
import { rootSaga as videosSaga } from '../../videoplayer/store/sagas';

export default function* rootSaga() {
  const sagas = [i18nSaga, videosSaga];

  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      }),
    ),
  );
}
