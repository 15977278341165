import { backgroundSize } from '@ariestech/atr-core-design-system';
import React, { useEffect, useState } from 'react';
import { useNavigateVideo } from 'src/videoplayer/hooks';

import lastra from '../../../_shared/assets/castello/3/lastra.jpg';

export const LastraPietra = (): JSX.Element => {
  const { useNavigateVideoRequest } = useNavigateVideo();
  const { execute } = useNavigateVideoRequest();

  const [testo, setTesto] = useState('.....');
  const [visibile, setvisibile] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // No longer need to cast to any - hooray for react!
    const punti = e.target.value.toUpperCase() + '.....';

    setTesto(punti.substr(0, 5));
  };

  useEffect(() => {
    if (testo.toUpperCase() === 'VOLTA') {
      setvisibile(true);
    }
  }, [testo]);

  const goOn = () => {
    execute('quarto');
  };

  return (
    <div
      style={{
        width: '60%',
        height: '80%',
        borderStartEndRadius: '50%',
        borderEndStartRadius: '50px',
        backgroundImage: 'url(/castellostregato/lastra/12-pietra.jpg)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'start',
          alignItems: 'start',
        }}
      >
        <div className='lastracontent'>
          STRA<span className='dina'>{testo} </span>
        </div>
        <div className='lastracontent'>
          SCON<span className='dina'>{testo}</span>
        </div>
        <div className='lastracontent'>
          GIRA<span className='dina'>{testo}</span>{' '}
        </div>
        <div className='lastracontent'>
          CAPO<span className='dina'>{testo}</span>
        </div>

        <div className='lastracontent'>
          STA<span className='dina'>{testo} </span>{' '}
        </div>
        <div className='lastracontent'>
          TRA<span className='dina'>{testo}</span>{' '}
        </div>
        <div className='lastracontent'>
          COIN<span className='dina'>{testo}</span>{' '}
        </div>
        <div className='lastrainput'>
          <input
            type='text'
            placeholder='..... scrivi la parola'
            readOnly={visibile}
            onChange={handleChange}
          ></input>

          {visibile ? (
            <button
              className='clickable buttonlastra'
              onClick={() => {
                goOn();
              }}
            >
              ESATTO! Clicca per proseguire
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
