import React, { useEffect, useState } from 'react';
import { useNavigateVideo } from 'src/videoplayer/hooks';

import lastra from '../../../_shared/assets/castello/3/lastra.jpg';
import { MatchButton } from '../MatchButton/MatchButton';

export const Farfalle = (): JSX.Element => {
  const { useNavigateVideoRequest } = useNavigateVideo();
  const { execute } = useNavigateVideoRequest();

  const [visibile, setvisibile] = useState(false);

  const goOn = () => {
    execute('quinto');
  };

  return (
    <div
      style={{
        width: '60%',
        height: '80%',
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: '',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/castellostregato/farfalle/fa1.png`}
        style={{ width: 159 }}
      ></img>
      <div style={{ flexDirection: 'row', display: 'flex' }}>
        <MatchButton
          src={'./castellostregato/farfalle/fa2.png'}
          correct={false}
          style={{ width: 159, height: 159 }}
        />
        <MatchButton
          src={'./castellostregato/farfalle/fa3.png'}
          correct={false}
          style={{ width: 159, height: 159 }}
        />
        <MatchButton
          src={'./castellostregato/farfalle/fa4.png'}
          correct={false}
          style={{ width: 159, height: 159 }}
        />

        <MatchButton
          src={'./castellostregato/farfalle/faok.png'}
          correct={true}
          rightClicked={() => {
            setvisibile(true);
          }}
          style={{ width: 159, height: 159 }}
        />
        <MatchButton
          src={'./castellostregato/farfalle/fa5.png'}
          correct={false}
          style={{ width: 159, height: 159 }}
        />
        <MatchButton
          src={'./castellostregato/farfalle/fa6.png'}
          correct={false}
          style={{ width: 159, height: 159 }}
        />
        <MatchButton
          src={'./castellostregato/farfalle/fa7.png'}
          correct={false}
          style={{ width: 159, height: 159 }}
        />

        <MatchButton
          src={'./castellostregato/farfalle/fa8.png'}
          correct={false}
          style={{ width: 159, height: 159 }}
        />
      </div>
      {visibile ? (
        <button
          className='clickable buttonlastra'
          onClick={() => {
            goOn();
          }}
        >
          ESATTO! Clicca per proseguire
        </button>
      ) : null}
    </div>
  );
};
