import { createGeneralHook } from '@ariestech/atr-core-requests-status-management';
import { videos, selectors } from '../store/';
import { useSelector } from 'react-redux';

export const useVideos = () => {
  const useVideoRequest = createGeneralHook('videos', videos);

  const keySelector = selectors.selectVideosList;

  const videoList = useSelector(keySelector);

  return { videoList, useVideoRequest };
};
