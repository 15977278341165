export const keyframes = {
  loading: {
    '0%': {
      transform: 'translateX(-100%)',
    },
    '100%': {
      transform: 'translateX(100%)',
    },
  },
  popIn: {
    '0%': {
      opacity: 0,
      transform: 'translate(50%, 50%) scale(1.2)',
    },
    '100%': {
      opacity: 1,
      transform: 'translate(50%, 50%) scale(1.0)',
    },
  },
};

export const animations = {
  loading: {
    name: 'loading',
    duration: 1,
    repetitions: 'infinite',
  },
  popIn: {
    name: 'popIn',
    duration: 0.5,
    repetitions: 1,
  },
};
