/**
 * @packageDocumentation
 * @module @ariestech/atr-core-i18n
 */
import I18n, { InterpolateOptions } from 'i18n-js';
import { useSelector } from 'react-redux';
import selectors from '../selectors';
import { useCallback } from 'react';

export enum DateFormat {
  Day = 'date.formats.day',
  Short = 'date.formats.short',
  Long = 'date.formats.long',
  LongMonth = 'date.formats.longMonth',
  ShortMonth = 'date.formats.shortMonth',
  DayAndMonth = 'date.formats.dayAndMonth',
  DayAndFullDate = 'date.formats.dayAndFullDate',
  LongMonthAndFullYear = 'date.formats.longMonthAndFullYear',
  MonthAndDay = 'date.formats.monthAndDay',
  ShortDayAndMonth = 'date.formats.shortDayAndMonth',
  DayNumber = 'date.formats.dayNumber',
}

const formattingFunction = (
  format: DateFormat | string,
  value: number | string | Date,
  options?: InterpolateOptions,
) => I18n.l(format, value, options);

/**
 * Returns a localization function that can be used to format date and time.
 * It Auto rerenders on language changes.
 */
export function useDateTimeFormatting() {
  const currentLanguage = useSelector(selectors.selectCurrentLanguage);

  const format = useCallback(
    (
      format: DateFormat | string,
      value: number | string | Date,
      options?: InterpolateOptions,
    ) =>
      formattingFunction(format, value, {
        locale: currentLanguage,
        ...(options || {}),
      }),
    [currentLanguage],
  );

  return format;
}
