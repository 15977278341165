import React, {
  useCallback,
  ReactEventHandler,
  ReactElement,
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useTranslations } from '../../../language/hooks';
import { Flex, Typography } from '@ariestech/atr-core-web-ui';
import { Video } from '../../store';
import videojs, { VideoJsPlayer } from 'video.js';

import { useVideojs } from '../../hooks';
import { useNavigateVideo } from 'src/videoplayer/hooks';
import { useSpring, config } from 'react-spring';
import { useWindowSize } from 'src/_shared/hooks';
import { useCurrentLanguage } from '../../../language/hooks/useCurrentLanguage';
import { ActionHandlersRederer } from '../ActionHandlersRenderer/ActionHandlersRederer';
import { BackgroundMusic } from '../BackgroundMusic/BackgroundMusic';
type Props = {
  video: Video;
};

export const SingleVideo = ({ video }: Props): JSX.Element => {
  /*   const t = useTranslations();
  const playerRef = useRef(); */
  const { currentVideo, useNavigateVideoRequest } = useNavigateVideo();
  const { execute, loading, success } = useNavigateVideoRequest();
  const [springprops, setSpringProps] = useState({});
  const [currentTime, setCurrentTime] = useState(0);
  const id = video.id;
  const [width, height] = useWindowSize();
  const [widthRatio, setWidthRatio] = useState(1);

  const [playing, setPlaying] = useState(false);

  const [videoLanguage, setVideoLanguage] = useState('');

  const currentLanguage = useCurrentLanguage();
  const props = useSpring(springprops);

  useEffect(() => {
    //console.log('cambiato lnguage', currentLanguage);
    setVideoLanguage(currentLanguage);
  }, [currentLanguage]);

  useEffect(() => {
    if (loading && currentVideo.id === id) {
      setSpringProps({
        to: { opacity: 0 },
        from: { opacity: 1 },
        config: config.molasses,
      });
    }
    if (success && currentVideo.id === id) {
      setSpringProps({
        to: { opacity: 1 },
        from: { opacity: 0 },
        config: config.molasses,
      });
    }
  }, [loading, success, currentVideo, id]);

  const onPlay = (currentTime?: number) => {
    console.log('REF', vjsRef);
    setPlaying(true);

    //console.log(`${id} Video played at:  ${currentTime}`);
  };

  const onPause = (currentTime?: number) => {
    //console.log(`${id} Video paused at ${currentTime}`);
  };

  const onEnd = (currentTime?: number) => {
    setPlaying(false);
    if (video.nextId && !video.loop) {
      //console.log(`${id} lancio ${video.nextId}`);
      execute(video.nextId);
    }

    // console.log(`${id} Video ended at ${currentTime}`);
  };

  const onReady = (player: VideoJsPlayer) => {
    /*     console.log('SRC', player.currentSource());
    console.log('SRCS', player.currentSources());
    console.log('SRC1', player.currentSrc()); */
  };

  const onTimeUpdate = (currentTime: number) => {
    // console.log(`${id} Video current time is ${currentTime}`);
    setCurrentTime(currentTime);
  };

  const { vjsId, vjsRef, vjsClassName } = useVideojs({
    src: video.src,
    controls: false,
    autoplay: true,
    responsive: true,
    fluid: true,
    language: currentLanguage,
    loop: video.loop || false,
    bigPlayButtonCentered: false,
    tracks: video.textTracks,
    onPlay,
    onPause,
    onEnd,
    onTimeUpdate,
    onReady,
    vr: video.vr,
    projection: video.projection || '360',
  });

  //aggiorno l'aspect ratio per posizionare gli elementi in base alla dimensione
  //della finsetra basandomi su un formato del video originale
  useEffect(() => {
    const VideoWidth = (vjsRef?.current as any)?.videoWidth | 0;

    const clientWidth = (vjsRef?.current as any)?.clientWidth | 0;

    if (VideoWidth > 0 && clientWidth > 0) {
      setWidthRatio(clientWidth / VideoWidth);
    }
  }, [vjsRef, width, playing]);

  const RenderInteraction = useCallback(() => {
    return (
      <>
        {video.backgroundMusic ? (
          <BackgroundMusic
            src={video.backgroundMusic}
            loop={video.backgroundLoop}
            volume={video.backgroundVolume || 0.4}
          />
        ) : null}
        {currentVideo.handlers?.map(h => (
          <ActionHandlersRederer
            widthRatio={widthRatio}
            key={h.fromTime}
            handler={h}
            currentTime={currentTime}
          ></ActionHandlersRederer>
        ))}
        {/*    <div style={{ position: 'fixed', bottom: 0, backgroundColor: 'white' }}>
          currentTime: {currentTime} ratio: {widthRatio} - Width:{' '}
          {(vjsRef?.current as any)?.clientWidth}, Height:{' '}
          {(vjsRef?.current as any)?.clientHeight}
        </div> */}
      </>
    );
  }, [currentVideo, currentTime, widthRatio]);

  return (
    <>
      <div data-vjs-player>
        <video
          ref={vjsRef}
          id={vjsId}
          className={`${vjsClassName} absvideo`}
          playsInline
        />
      </div>
      <div className='singlevideoInteraction'>{RenderInteraction()}</div>
    </>
  );
};
