export const switchStyle = {
  transition: '0.4s',
  borderRadius: 'xxl',
  bg: 'grey/light',
  textStyle: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  variants: {
    checked: {
      bg: 'success',
      states: {
        '&:before': {
          transform: 'translateX(100%)',
        },
      },
    },
    disabled: {
      pointerEvents: 'none',
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  },
};
