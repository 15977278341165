export const button = {
  px: 'xl',
  height: '40px',
  minWidth: '40px',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 's',
  borderColor: 'transparent',
  borderStyle: 'solid',
  cursor: 'pointer',
  display: 'flex',
  width: '100%',
  variants: {
    primary: {
      bg: 'positive',
      outline: 'none',
      textStyle: {
        color: 'white',
      },
      states: {
        '&:hover': {
          bg: 'positive/hover',
        },
        '&:disabled': {
          bg: 'positive/disabled',
        },
      },
    },
    'primary/negative': {
      bg: 'negative',
      outline: 'none',
      textStyle: {
        color: 'positive',
      },
      states: {
        '& > p:hover': {
          color: 'positive/hover',
        },
        '&:disabled': {
          color: 'positive/disabled',
        },
      },
    },
    'primary/gradient': {
      gradient: 'primary',
      bg: 'transparent',
      borderStyle: 'none',
      outline: 'none',
      textStyle: {
        color: 'white',
      },
      states: {
        '&:hover': {
          gradient: 'primary/hover',
        },
      },
    },
    secondary: {
      bg: 'transparent',
      borderWidth: 'xs',
      borderColor: 'positive',
      outline: 'none',
      textStyle: {
        color: 'positive',
      },
      states: {
        '&:hover > p': {
          color: 'positive/hover',
        },
        '&:hover': {
          borderColor: 'positive/hover',
        },
        '&:disabled': {
          color: 'positive/disabled',
          borderColor: 'positive/disabled',
        },
      },
    },
    'secondary/negative': {
      bg: 'transparent',
      borderWidth: 'xs',
      borderColor: 'negative',
      outline: 'none',
      textStyle: {
        color: 'negative',
      },
    },
    special: {
      gradient: 'primary',
      height: 38,
      px: 30,
      borderRadius: 'xl',
      borderStyle: 'none',
      outline: 'none',
      textStyle: {
        color: 'white',
      },
    },
    'special/negative': {
      height: 38,
      px: 30,
      bg: 'white',
      borderRadius: 'xl',
      textGradient: 'primary',
      outline: 'none',
    },
    'special/secondary': {
      bg: 'primary',
      height: 38,
      px: 30,
      borderRadius: 'xl',
      outline: 'none',
      textStyle: {
        color: 'white',
      },
      states: {
        '&:hover': {
          bg: 'positive/hover',
        },
        '&:disabled': {
          bg: 'positive/disabled',
        },
      },
    },
    'special/secondary/negative': {
      outline: 'none',
      height: 38,
      px: 30,
      bg: 'white',
      borderRadius: 'xl',
      textGradient: 'primary',
    },
  },
};
