const primaryColors = {
  // primary colors
  primary: '#4070ff',
  'primary/opacity/half': 'rgba(64, 112, 255, 0.5)',
  success: '#05b540',
  error: '#df0000',
  warning: '#ff8400',
  white: '#ffffff',
  'white/opacity/half': 'rgba(255, 255, 255, 0.5)',
  black: '#000000',
  transparent: 'transparent',
  background: '#ffffff',
};
const greyPalette = {
  // grey palette has 2 alias
  dark: '#2c2c2c',
  'grey/dark': '#2c2c2c',
  // strong grey
  'grey/strong': '#606060',
  // regular grey has 2 alias
  grey: '#b3b3b3',
  'grey/regular': '#b3b3b3',
  // light grey has 3 alias
  light: '#ededed',
  'grey/light': '#ededed',
  'off/white': '#ededed',
};
const spendingColors = {
  // colors for spending
  income: '#1e3cff',
  outcome: '#ff9b00',
  unaccountedOutcome: '#ffc366',
};
const buttonColors = {
  // used for buttons
  positive: primaryColors.primary,
  'positive/hover': '#2c4dab',
  'positive/disabled': primaryColors['primary/opacity/half'],
  negative: primaryColors.white,
  'negative/hover': '#c1d1fe',
  'negative/disabled': primaryColors['white/opacity/half'],
};
const gradientColors = {
  // used for gradients
  'gradient/start': '#ff9b00',
  'gradient/middle': '#ff3201',
  'gradient/end': '#ff00a9',
  'gradient/primary/hover/start': '#D88C16',
  'gradient/primary/hover/middle': '#DE3F17',
  'gradient/primary/hover/end': '#E013E0',
};

export const colors = {
  ...primaryColors,
  ...greyPalette,
  ...spendingColors,
  ...buttonColors,
  ...gradientColors,
};
