import { createAction } from 'typesafe-actions';
import { Navigation, Video } from '../types';

//viene invocata dalla UI per avviare le saga in ascolto
export const navigate = createAction('navigation/NAVIGATE')<Navigation>();

export const setCurrentVideo = createAction(
  'navigation/SET_CURRENT_VIDEO',
)<Video>();
//viene invocata dalle saga per avviare il caricamneto del json dei video(attiva i reducer in ascolto su questa action)
export const navigateRequest = createAction(
  'navigation/NAVIGATE_REQUEST',
)<Navigation>();

//invocata dalla saga , attiva i reducer in ascolto in caso di buon fine passando
//l'array dei video parsati
export const navigateRequestSuccess = createAction(
  'navigation/NAVIGATE_REQUEST_SUCCESS',
)();

//invocata dalla saga , attiva i reducer in ascolto in caso di errore nel caricamento
export const navigateRequestFailure = createAction(
  'navigation/NAVIGATE_REQUEST_FAILURE',
)();
