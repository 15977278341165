import {
  requestsStatusReducerKey,
  requestsStatusReducer,
} from '@ariestech/atr-core-requests-status-management';
import { reducers as i18nReducer } from '../../language';
import {
  rootReducer as reducerVideos,
  navigationReducer as reducerNavigation,
  navigationReducer,
} from '../../videoplayer/store';

const staticReducers = {
  [requestsStatusReducerKey]: requestsStatusReducer,
  i18n: i18nReducer,
  videos: reducerVideos,
  navigation: navigationReducer,
};

export default staticReducers;
