export const radio = {
  border: '1px solid',
  borderColor: 'grey/light',
  borderRadius: 'round',
  width: 'xxl',
  height: 'xxl',
  position: 'relative',
  states: {
    '&:after': {
      content: `''`,
      position: 'absolute',
      display: 'none',
    },
  },
  textStyle: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  variants: {
    checked: {
      border: '1px solid',
      borderColor: 'primary',
      states: {
        '&:after': {
          display: 'block',
          borderRadius: 'round',
          bg: 'primary',
          width: '50%',
          height: '50%',
          top: 0,
          left: 0,
          transform: 'translate(50%, 50%)',
          animation: 'popIn',
        },
      },
    },
    disabled: {
      bg: 'grey/light',
      pointerEvents: 'none',
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  },
};
