import { createReducer } from 'typesafe-actions';
import {
  NavigationState,
  VideoNavigation,
  VideoNavigationRequest,
  VideoNavigationRequestFailure,
  VideoNavigationRequestSuccess,
  SetCurrentVideo,
} from '../types';
import * as action from '../actions';

const defaultState: NavigationState = {
  currentVideo: { id: '', src: '', loop: false },
};

export const navigateVideoRequestCase = (
  state: NavigationState,
  action: VideoNavigationRequest,
): NavigationState => ({
  ...state,
  rollbackData: {
    ...state.currentVideo,
  },
});
export const navigateVideoSuccessCase = (
  state: NavigationState,
  action: VideoNavigationRequestSuccess,
): NavigationState => ({
  ...state,
  rollbackData: undefined,
});

export const navigateVideoFailureCase = (
  state: NavigationState,
  action: VideoNavigationRequestFailure,
): NavigationState => ({
  ...state,
  currentVideo: { ...state.currentVideo },
  rollbackData: undefined,
});

export const setCurrentVideo = (
  state: NavigationState,
  action: SetCurrentVideo,
): NavigationState => {
  /*  if (!newVideo) {
    throw Error('video con id ' + action.payload.idVideo + ' non trovato');
  } */
  return {
    ...state,
    currentVideo: { ...action.payload },
    rollbackData: undefined,
  };
};
export const navigationReducer = createReducer<
  NavigationState,
  VideoNavigation
>(defaultState)
  .handleAction(action.navigateRequest, navigateVideoRequestCase)
  .handleAction(action.navigateRequestSuccess, navigateVideoSuccessCase)
  .handleAction(action.setCurrentVideo, setCurrentVideo)
  .handleAction(action.navigateRequestFailure, navigateVideoFailureCase);
