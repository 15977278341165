import { createSelector } from 'reselect';
import { NavigationState } from '../types';

const selectState = (state: { navigation: NavigationState }) => state;

export const selectNavigationState = createSelector(
  selectState,
  current => current.navigation,
);

export const selectCurrentVideo = createSelector(selectNavigationState, ({currentVideo}) => currentVideo);

