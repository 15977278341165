import React, {
    useCallback,
    ReactEventHandler,
    ReactElement,
    useRef,
    useEffect,
    useState,
    useMemo,
  } from 'react';

  import videojs, { VideoJsPlayer } from 'video.js';
  import { useVideojs } from '../../hooks';

  type Props = {
    src: string;
    loop:boolean;
    volume?:number;
  };

export const BackgroundMusic = ({ src, loop, volume }: Props): JSX.Element => {

    const { vjsId, vjsRef, vjsClassName } = useVideojs({
        src: src,
        controls: false,
        autoplay: true,
        loop: loop || false,
        defaultVolume: volume || 0.4
      });

      return (
        <>
            <audio
            
              ref={vjsRef}
              id={vjsId}
              className={`${vjsClassName} backgroundmusic`}
              playsInline
            />
        </>
      );


  }