export const textInput = {
  borderColor: 'primary',
  borderStyle: 'solid',
  variants: {
    primary: {
      borderColor: 'primary',
    },
    secondary: {
      borderColor: 'light',
    },
    rounded: {
      borderColor: 'dark',
      borderWidth: 'xs',
      borderRadius: 'round',
    },
  },
};
