import {
  addRequestManagement,
  handleSagaError,
} from '@ariestech/atr-core-requests-status-management';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as actionsVideos from '../actions';

function* navigationRequestSaga(
  action: ReturnType<typeof actionsVideos.navigate>,
) {
  yield put(actionsVideos.navigateRequest(action.payload));

  try {
    const state = yield select();
    //console.warn({ state }, 'XXX');

    const currentvideo = state.videos.videos.find(
      x => x.id === action.payload.idVideo,
    );
    //console.log({ currentvideo }, 'CURRENT');
    yield put(actionsVideos.setCurrentVideo(currentvideo));
    yield put(actionsVideos.navigateRequestSuccess());
  } catch (error) {
    yield put(actionsVideos.navigateRequestFailure());
    yield call(handleSagaError, error);
  }
}

export function* getNavigationSaga() {
  yield takeEvery(
    actionsVideos.navigate,
    addRequestManagement(navigationRequestSaga, 'navigation'),
  );
}
