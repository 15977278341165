import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Homepage } from '../../homepage/views/Homepage';
import { VideoPlayer } from '../../videoplayer/views/VideoPlayer/VideoPlayer';
const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <VideoPlayer />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
