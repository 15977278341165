import {
  addRequestManagement,
  handleSagaError,
} from '@ariestech/atr-core-requests-status-management';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { selectors as i18nSelectors } from '../../../language';
import * as actionsVideos from '../actions';
import { callParseVideoJson } from '../api';

function* videosRequestSaga(action: ReturnType<typeof actionsVideos.videos>) {
  yield put(actionsVideos.videosRequest());
  try {
    const state = yield select();
    const data: any = yield call(
      callParseVideoJson,
      i18nSelectors.selectCurrentLanguage(state),
    );
    const currentvideo = data.find(x => x.id === 'intro');
    console.log({ currentvideo }, 'CURRENT');
    yield put(actionsVideos.setCurrentVideo(currentvideo));
    yield put(actionsVideos.videosRequestSuccess(data));
  } catch (error) {
    yield put(actionsVideos.videosRequestFailure());
    yield call(handleSagaError, error);
  }
}

export function* getVideosSaga() {
  yield takeEvery(
    actionsVideos.videos,
    addRequestManagement(videosRequestSaga, 'videos'),
  );
}
