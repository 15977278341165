/**
 * @packageDocumentation
 * @module @ariestech/atr-core-i18n
 */
import { call, delay, put, race, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import I18n from 'i18n-js';
import { callAuthorize } from '../api/authorize';
import { getTranslations } from '../api/getTranslations';
import actions from '../actions';
import { Translation, LanguageType, Priority } from '../types';
import { config } from '../config';
import { deepMerge } from '../utils';

// eslint-disable-next-line no-underscore-dangle
declare let __DEV__: boolean;

const I18N_STORAGE_KEY = '@i18n/';

/**
 * Empty object if not found
 */
async function loadTranslationsFromStorage(languageCode: string) {
  try {
    const data = await localStorage.getItem(
      `${I18N_STORAGE_KEY}${languageCode}`,
    );
    return data ? JSON.parse(data) : {};
  } catch (e) {
    return {};
  }
}

async function saveTranslationsIntoStorage(
  languageCode: string,
  translations?: Translation,
) {
  if (translations) {
    try {
      await localStorage.setItem(
        `${I18N_STORAGE_KEY}${languageCode}`,
        JSON.stringify(translations),
      );
    } catch (e) {
      console.error('error saving translations', e);
    }
  }
}

// TODO take them from backend?
export const date = {
  formats: {
    day: '%a',
    dayNumber: '%-d',
    short: '%d/%m/%Y',
    long: '%d %B %Y',
    longMonth: '%B',
    shortMonth: '%b',
    dayAndMonth: '%d %b',
    dayAndFullDate: '%a, %d/%m/%Y',
    longMonthAndFullYear: '%B %Y',
    monthAndDay: '%b %d',
    shortDayAndMonth: '%-d/%-m',
  },
  abbr_day_names: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
  day_names: [
    'Domenica',
    'Lunedì',
    'Martedì',
    'Mercoledì',
    'Giovedì',
    'Venerdì',
    'Sabato',
  ],
  abbr_month_names: [
    null,
    'Gen',
    'Feb',
    'Mar',
    'Apr',
    'Mag',
    'Giu',
    'Lug',
    'Aug',
    'Set',
    'Ott',
    'Nov',
    'Dic',
  ],
  month_names: [
    null,
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ],
};

export function addTranslation(
  languageCode: string,
  translations: object,
): void {
  I18n.translations = {
    ...I18n.translations,
    [languageCode]: { ...translations, date },
  };
}

function getUserPreferredLanguage(defaultLanguage: LanguageType): LanguageType {
  return defaultLanguage;
}

/**
 * tries to get translation from assets, localStorage, api, and merge everything
 */
function* initializeTranslationsSaga(): SagaIterator {
  const { defaultLanguage } = config;
  const defaults = config.localePath;

  if (config.priority === Priority.Locale) {
    I18n.locale = defaultLanguage.languageTag;
    I18n.defaultLocale = defaultLanguage.languageTag;

    addTranslation(
      defaultLanguage.languageTag,
      defaults[defaultLanguage.languageTag] as Translation,
    );
  }

  try {
    const { languageTag: deviceLanguage } = getUserPreferredLanguage(
      defaultLanguage,
    );

    // Check if endPoint exist
    if (config.endPointAuthorize !== '' || config.endPointI18n !== '') {
      let token;
      if (config.endPointAuthorize) {
        const { data, timeout: tokenTimeout } = yield race({
          data: call(callAuthorize, config.endPointAuthorize, config.clientID),
          timeout: delay(3 * 1000),
        });
        if (tokenTimeout) {
          throw 'Unable to retrieve token fallback to default';
        }
        token = data?.auth?.access_token;
      }
      // Do not wait more than 4 seconds before translations are fetched
      const { freshTranslations } = yield race({
        timeout: delay(3 * 1000),
        freshTranslations: call(getTranslations, {
          language: deviceLanguage,
          i18nEndpoint: config.endPointI18n,
          token,
        }),
      });

      const realLanguage =
        freshTranslations?.locale || defaultLanguage.languageTag;

      const savedTranslations = yield call(
        loadTranslationsFromStorage,
        realLanguage,
      );

      I18n.locale = realLanguage;
      I18n.defaultLocale = realLanguage;
      I18n.fallbacks = true;

      const fallbackTranslations =
        defaults[realLanguage as keyof typeof defaults] ||
        defaults[defaultLanguage.languageTag];

      const translations = deepMerge(
        fallbackTranslations,
        savedTranslations,
        freshTranslations?.i18n,
      );

      addTranslation(realLanguage, translations);

      yield put(actions.initializeTranslationsSuccess(realLanguage));
      yield call(
        saveTranslationsIntoStorage,
        realLanguage,
        freshTranslations?.i18n,
      );
    } else {
      yield put(
        actions.initializeTranslationsSuccess(defaultLanguage.languageTag),
      );
    }
  } catch (error) {
    if (config.priority === Priority.Remote) {
      I18n.locale = defaultLanguage.languageTag;
      I18n.defaultLocale = defaultLanguage.languageTag;
      I18n.fallbacks = true;

      addTranslation(
        defaultLanguage.languageTag,
        defaults[defaultLanguage.languageTag] as Translation,
      );
    }

    yield put(
      actions.initializeTranslationsSuccess(defaultLanguage.languageTag),
    );
  }
}

export default function* authorizeSaga() {
  yield takeEvery(
    actions.initializeTranslations,
    (): SagaIterator => initializeTranslationsSaga(),
  );
}
