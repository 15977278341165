import React, { useEffect, useState } from 'react';
import { useNavigateVideo } from 'src/videoplayer/hooks';
import { animated, useSpring, config, useTransition } from '@react-spring/web';

export type Props = React.ComponentProps<typeof animated.div> & {
  src: string;
  correct: boolean;
  rightClicked?: () => void;
};

export const MatchButton = ({
  src,
  correct,
  rightClicked,
  ...props
}: Props): JSX.Element => {
  const [clicked, setClicked] = useState(false);
  const style = useSpring({
    to: clicked
      ? [
          { borderColor: correct ? 'green' : 'red' },
          { borderColor: correct ? 'green' : 'black' },
        ]
      : { borderColor: 'black' },
    from: { borderColor: 'black' },
    config: { duration: 1000 },

    onRest: () => {
      if (clicked && correct && rightClicked) {
        rightClicked();
      } else {
        setClicked(false);
      }
    },
  });

  const click = () => {
    setClicked(true);
  };

  return (
    <animated.div
      {...props}
      className='clickable'
      style={{
        ...props?.style,
        display: 'flex',
        flex: '1',
        backgroundImage: `url(${src})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderWidth: 10,
        borderStyle: 'solid',
        ...style,
      }}
      onClick={() => {
        click();
      }}
    ></animated.div>
  );
};
