import customFetch from '@ariestech/atr-core-fetch';
import { env } from '@ariestech/atr-core-env';

function encodeRequest(
  jsonData: { [key: string]: string | number } = {},
): string {
  return Object.entries(jsonData)
    .map(x => {
      return `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`;
    })
    .join('&');
}

export async function callAuthorize(
  endPointAuth: string,
  client_id: string,
): Promise<{
  auth: { access_token: string };
}> {
  return customFetch
    .native<{ auth: { access_token: string } }>(`${env.host.base}${endPointAuth}`, {
      method: 'POST',
      body: encodeRequest({
        client_id,
      }),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    .json();
}
