export const card = {
  borderRadius: 'l',
  border: 'none',
  p: 'm',
  bg: 'grey/light',
  overflow: 'hidden',
  variants: {
    shadow: {
      boxShadow: '0 3px 8px 1px rgba(0, 0, 0, 0.4)',
    },
    disabled: {
      pointerEvents: 'none',
      opacity: 0.4,
    },
  },
};
