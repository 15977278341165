import { createGeneralHook } from '@ariestech/atr-core-requests-status-management';
import { navigate, navigationSelectors } from '../store/';
import { useSelector } from 'react-redux';

export const useNavigateVideo = () => {
  const useNavigateVideoRequest = createGeneralHook(
    'navigation',
    (idVideo: string) => navigate({ idVideo }),
  );

  const keySelector = navigationSelectors.selectCurrentVideo;

  const currentVideo = useSelector(keySelector);

  return { currentVideo, useNavigateVideoRequest };
};
