export const formInput = {
  borderBottomWidth: 'xs',
  borderBottomStyle: 'solid',
  height: 30,
  labelVariant: 'p3',
  placeholderVariant: 'p2',
  color: 'dark',
  colorOnFocus: 'primary',
  py: 'none',
  containerStyle: {
    paddingTop: 15,
  },
};
