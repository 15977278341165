import { createAction } from 'typesafe-actions';
import { Video } from '../types';

//viene invocata dalla UI per avviare le saga in ascolto
export const videos = createAction('videos/LOADVIDEOS')();
//viene invocata dalle saga per avviare il caricamneto del json dei video(attiva i reducer in ascolto su questa action)
export const videosRequest = createAction('videos/VIDEOS_REQUEST')();

//invocata dalla saga , attiva i reducer in ascolto in caso di buon fine passando
//l'array dei video parsati
export const videosRequestSuccess = createAction(
  'videos/VIDEOS_REQUEST_SUCCESS',
)<Video[]>();

//invocata dalla saga , attiva i reducer in ascolto in caso di errore nel caricamento
export const videosRequestFailure = createAction(
  'videos/VIDEOS_REQUEST_FAILURE',
)();
