import React, { useCallback, useEffect } from 'react';
import { useTranslations } from '../../../language/hooks';
import { Flex, Typography, Asset } from '@ariestech/atr-core-web-ui';

export const Homepage = () => {
  const t = useTranslations();
  return (
    <Flex justifyContent='center'>
      <Flex alignItems='center' justifyContent='center' flexDirection='column'>
        <Typography textAlign='center' variant='p1'>
          {t('homepage.title')}
        </Typography>
      </Flex>
    </Flex>
  );
};
