export const animatedShape = {
  position: 'relative',
  bg: 'grey/regular',
  overflow: 'hidden',
  states: {
    '&:after': {
      content: `''`,
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      gradient: 'loading',
      animation: 'loading',
    },
  },
  variants: {
    rectangular: {
      height: 20,
    },
    round: {
      width: 20,
      height: 20,
      borderRadius: 'round',
    },
  },
};
