export type Config = {
    defaultLanguage: {
        languageCode: string,
        isRTL: boolean,
        languageTag: string
    },
    localePath: {
        [K: string]: any
    },
    endPointI18n: string,
    endPointAuthorize: string,
    priority: Priority,
    clientID: string
}

export enum Priority {
    Locale = "Locale",
    Remote = "Remote"
}
