import React from 'react';
import './App.css';
import Routes from './_shared/route/index';
import { ThemeProvider } from '@ariestech/atr-core-web-ui';
import { theme } from './_shared/styles';
import * as assets from './_shared/assets';
import 'video.js/dist/video-js.css';
import 'videojs-vr/dist/videojs-vr.css';
function App() {
  React.useLayoutEffect(() => {
    document.onfullscreenchange = () =>
      // setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);
      console.log('FULLSCREEEN CHANGHED');

    return () => (document.onfullscreenchange = undefined);
  });

  return (
    <ThemeProvider theme={theme as any} assets={assets}>
      <Routes />
    </ThemeProvider>
  );
}

export default App;
