import {
  addRequestManagement,
  handleSagaError,
} from '@ariestech/atr-core-requests-status-management';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import actions from '../actions';

function* changeLanguageRequestSaga(
  action: ReturnType<typeof actions.changeLanguage>,
) {
  yield put(actions.changeLanguageRequest(action.payload));
}

export function* getchangeLanguageSaga() {
  yield takeEvery(
    actions.changeLanguage,
    addRequestManagement(changeLanguageRequestSaga, 'changelanguage'),
  );
}
