export const fontFamilies = {
  regular: 'GTAmerica-Regular',
  medium: 'GTAmerica-Medium',
  bold: 'GTAmerica-Bold',
  black: 'GTAmerica-Black',
  extended: 'GTAmerica-Extended',
  extendedBold: 'GTAmerica-Extended-Bold',
};

export const fontSizes = {
  none: '0px',
  xxs: '12px',
  xs: '14px',
  s: '16px',
  m: '18px',
  l: '20px',
  xl: '22px',
  xxl: '26px',
};

export const lineHeights = {
  xxs: '20px',
  xs: '22px',
  s: '24px',
  m: '26px',
  l: '28px',
  xl: '30px',
  xxl: '32px',
  xxxl: '38px',
};
