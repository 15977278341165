import { createSelector } from 'reselect';
import { State } from '../types';

const selectState = (state: { videos: State }) => state;

export const selectVideosState = createSelector(
  selectState,
  slice => slice.videos,
);

export const selectVideosList = createSelector(selectVideosState, slice => {
  //console.warn(slice);
  return slice.videos;
});

export const getVideoById = (idVideo: string) =>
  createSelector(selectVideosState, slice => {
    const finded = slice.videos.find(x => x.id === idVideo);

    return finded;
  });
