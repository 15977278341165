import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslations } from '../../../language';
import { Flex, Typography, Asset, Box } from '@ariestech/atr-core-web-ui';
import { useVideos, useNavigateVideo } from 'src/videoplayer/hooks';
import menujson from '../../../_shared/assets/lottie/menu.json';
import Lottie from 'react-lottie-player';
import { useSpring, animated } from 'react-spring';
import { useChangeLanguage } from './../../../language/hooks/useChangeLanguage';

export const NavigationBar = () => {
  const { currentVideo, useNavigateVideoRequest } = useNavigateVideo();
  const { execute } = useNavigateVideoRequest();

  const { useChangeLanguageRequest } = useChangeLanguage();

  const change = useChangeLanguageRequest().execute;

  const { videoList, useVideoRequest } = useVideos();
  const [animationPaused, setAnimationPaused] = useState(true);
  const [animationStopped, setAnimationStopped] = useState(true);
  const [forward, setForward] = useState(true);
  const [showNav, setShowNav] = useState(false);

  const styles = useSpring({ opacity: showNav ? 1 : 0 });

  const menu = useCallback(() => {
    return videoList
      .filter(x => x.showmenu)
      .map(v => (
        <Flex
          key={v.id}
          className='menuItem clickable'
          onClick={() => {
            setAnimationStopped(false);
            setShowNav(!showNav);
            execute(v.id);
          }}
        >
          {v.menudescription}
        </Flex>
      ));
  }, [videoList, showNav]);

  return (
    <>
      <div
        className='clickable'
        id='hamburger'
        onClick={() => {
          setAnimationStopped(false);
          setShowNav(!showNav);
        }}
      >
        <Lottie
          loop={false}
          autoPlay={false}
          animationData={menujson}
          segments={[0, 100]}
          speed={2.5}
          direction={forward ? 1 : -1}
          play={!animationStopped}
          onComplete={() => {
            setForward(!forward);
            setAnimationStopped(true);
          }}
          style={{ width: 50, height: 50 }}
        />
      </div>
      <animated.div style={styles} id='NavBar'>
        {menu()}
        <Flex
          className='menuItem clickable'
          onClick={() => {
            change('it');
            setShowNav(!showNav);
          }}
        >
          IT
        </Flex>
        <Flex
          className='menuItem clickable'
          onClick={() => {
            change('en');
            setShowNav(!showNav);
          }}
        >
          EN
        </Flex>
      </animated.div>
    </>
  );
};
