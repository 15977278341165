import React, {
  useCallback,
  CSSProperties,
  ButtonHTMLAttributes,
  HTMLProps,
  useMemo,
} from 'react';
import { ActionContents } from '../../store/types';
import { animated, useSpring } from 'react-spring';
import { useNavigateVideo } from 'src/videoplayer/hooks';
import { useVideoContext } from './../../hooks/useVideoContext';
import { useEcommerceContext } from './../../../ecommerce/hooks/useEcommerceContext';

export type ButtonProps = React.ComponentProps<typeof animated.div> & {
  content: ActionContents;
  widthRatio: number;
};

export const Button = ({
  content,
  widthRatio,
  ...props
}: ButtonProps): JSX.Element => {
  const springprops = useSpring(content.springStyle);
  const { useNavigateVideoRequest } = useNavigateVideo();
  const { videoctx } = useVideoContext();
  const { ecommctx } = useEcommerceContext();
  const navigate = useNavigateVideoRequest().execute;

  const top = useMemo(() => {
    const curtop = content.top * widthRatio;
    //console.log({ curtop });
    return curtop;
  }, [content.top, widthRatio]);

  const left = useMemo(() => {
    return content.left * widthRatio;
  }, [content.left, widthRatio]);

  const Img = () => {
    return content.src ? (
      <div className='mainImg'>
        <img src={content.src} alt={content.description}></img>
      </div>
    ) : null;
  };

  const doAction = () => {
    switch (content.action) {
      case 'addtocart':
        //ecommctx
        break;
      case 'customfunc':
        videoctx[content.funcToCall]();
        break;
      case 'navigate':
        navigate(content.navigateTo);
        break;
      case 'none':
        break;
      case 'showcontent':
        break;
      default:
        break;
    }
  };

  return (
    <animated.div
      {...props}
      style={{
        ...props?.style,
        ...content?.style,
        ...springprops,
        top: top,
        left: left,
        transform: `scale(${widthRatio})`,
        transformOrigin: 'top left',
      }}
      className={`handlercontainer ${content.className}`}
      onClick={() => {
        doAction();
      }}
    >
      {Img()}
      <div className='ButtonTitle'> {content.title}</div>
    </animated.div>
  );
};
