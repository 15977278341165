import configureStore from '../store/configureStore';
import * as reducerInjector from '../store/injectReducer';
import configureI18n from './i18n';

import { configureFetch } from './fetch';

export default function bootstrap() {
  const { store } = configureStore();

  reducerInjector.setStore(store);
  configureFetch(store);
  configureI18n(store);

  return {
    store,
  };
}
