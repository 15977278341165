import { button } from './button';

export const typography = {
  variants: {
    hero: {
      fontSize: 'l',
      fontFamily: 'extendedBold',
      fontWeight: 'bold',
      lineHeight: 'm',
      color: 'dark',
    },
    'hero/gradient': {
      fontSize: 'l',
      fontFamily: 'extendedBold',
      fontWeight: 'bold',
      lineHeight: 'm',
      textGradient: 'primary',
      width: 'fit-content',
    },
    'hero/negative': {
      fontSize: 'l',
      fontFamily: 'extendedBold',
      fontWeight: 'bold',
      lineHeight: 'm',
      color: 'white',
    },
    h1: {
      fontSize: 'xl',
      lineHeight: 'xl',
      fontFamily: 'bold',
      fontWeight: 'bold',
      color: 'dark',
    },
    'h1/gradient': {
      fontSize: 'xl',
      lineHeight: 'xl',
      fontFamily: 'bold',
      fontWeight: 'bold',
      textGradient: 'primary',
      width: 'fit-content',
    },
    'h1/negative': {
      fontSize: 'xl',
      lineHeight: 'xl',
      fontFamily: 'bold',
      fontWeight: 'bold',
      color: 'white',
    },
    h2: {
      fontSize: 'm',
      lineHeight: 's',
      fontFamily: 'bold',
      fontWeight: 'bold',
      color: 'dark',
    },
    h3: {
      fontSize: 's',
      lineHeight: 'xs',
      fontFamily: 'bold',
      fontWeight: 'bold',
      color: 'dark',
    },
    totalAmount: {
      fontSize: 'xxl',
      lineHeight: 'xxxl',
      fontFamily: 'black',
      color: 'dark',
    },
    p1: {
      fontSize: 'm',
      lineHeight: 'l',
      fontFamily: 'regular',
      color: 'dark',
    },
    p2: {
      fontSize: 's',
      lineHeight: 'xs',
      fontFamily: 'regular',
      color: 'dark',
    },
    p3: {
      fontSize: 'xs',
      lineHeight: 'xxs',
      fontFamily: 'regular',
      color: 'dark',
    },
    p4: {
      fontSize: 'xxs',
      lineHeight: 'xxs',
      fontFamily: 'regular',
      color: 'dark',
    },
    'button/text/primary': {
      fontSize: 'xs',
      lineHeight: 'xxs',
      fontFamily: 'medium',
      color: 'primary',
    },
    'button/text/primary/hover': {
      fontSize: 'xs',
      lineHeight: 'xxs',
      fontFamily: 'medium',
      color: 'positive/hover',
    },
    'button/text/secondary': {
      fontSize: 'xs',
      lineHeight: 'xxs',
      fontFamily: 'medium',
      textGradient: 'primary',
      width: 'fit-content',
    },
    'button/text/secondary/hover': {
      fontSize: 'xs',
      lineHeight: 'xxs',
      fontFamily: 'medium',
      textGradient: 'primary/hover',
      width: 'fit-content',
    },
    link: {
      fontSize: 'xs',
      lineHeight: 'xxs',
      fontFamily: 'medium',
      color: 'primary',
      states: {
        '&:hover': {
          fontSize: 'xs',
          lineHeight: 'xxs',
          fontFamily: 'medium',
          color: 'positive/hover',
        },
      },
    },
    caption: {
      fontSize: 'xxs',
      lineHeight: 'xxs',
      fontStyle: 'italic',
      fontFamily: 'regular',
      color: 'dark',
    },
    overline: {
      fontFamily: 'bold',
      fontSize: 'xs',
      lineHeight: 's',
      letterSpacing: '0.5px',
      color: 'dark',
      textTransform: 'uppercase',
    },
    cta: {
      color: 'positive',
      fontWeight: 'bold',
      fontFamily: 'bold',
      textDecorationLine: 'underline',
      textDecorationColor: 'positive',
      states: {
        '&:hover': {
          color: 'positive/hover',
        },
      },
    },
    buttonLink: {
      ...button,
      variants: undefined,
      ...button.variants['primary/gradient'],
      color: button.variants['primary/gradient'].textStyle.color,
    },
  },
};
