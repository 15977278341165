import { setConfig } from '@ariestech/atr-core-fetch';
import { Store } from 'redux';

const ACCESS_TOKEN_ERROR = 'Access Token expired';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function configureFetch(store: Store) {
  function tokenGetter(): { token: string; issuedAt: number } {
    return {
      token: '', // authSelectors.selectAuthToken(store.getState()), add your selector
      issuedAt: 0, // authSelectors.getTokenIssuedAt(store.getState()),
    };
  }

  async function shouldRefreshToken(error: Response): Promise<boolean> {
    try {
      const data = await error.clone().json();
      return (
        error.status === 401 && data?.fault?.faultstring === ACCESS_TOKEN_ERROR
      );
    } catch (e) {
      return false;
    }
  }

  async function tokenRefresher(): Promise<void> {
    try {
      // refresh token from your api and put it in the store
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Unable to refresh token', e);
      throw e;
    }
  }

  setConfig({
    tokenRefresher,
    shouldRefreshToken,
    tokenGetter,
  });
}
