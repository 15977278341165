import customFetch from '@ariestech/atr-core-fetch';
import { env } from '@ariestech/atr-core-env';

type Options = {
  language: string;
  i18nEndpoint: string;
  token?: string;
};
export async function getTranslations(
  options: Options,
): Promise<{ i18n: object; locale: string }> {
  const { language, i18nEndpoint, token } = options;
  try {
    return await customFetch
      .get<{ i18n: object; locale: string }>(
        `${token ? env.host.base : env.host.login}${i18nEndpoint}${language}`,
        {
          headers: token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {
                clientId: env.clientId,
              },
        },
      )
      .json();
  } catch (e) {
    return { i18n: {}, locale: language };
  }
}
