export const input = {
  outline: 'none',
  border: 'none',
  padding: 's',
  borderWidth: 'xxs',
  borderColor: 'grey/regular',
  borderStyle: 'solid',
  borderRadius: 's',
  variants: {},
};
