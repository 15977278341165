export const dropDown = {
  bg: 'transparent',
  labelStyle: { mb: 'xxs' },
  borderRadius: 's',
  shadow: 'soft',
  containerStyle: {
    borderRadius: 's',
  },
};

export const dropDownHeader = {
  outline: 'none',
  p: 's',
  bg: 'white',
  border: 0,
  borderRadius: 's',
  textStyle: {
    variant: 'p2',
    color: 'dark',
  },
  iconStyle: {
    color: 'primary',
  },
  px: 'm',
  height: '40px',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  display: 'flex',
  width: '100%',
};

export const dropDownOptionsContainer = {
  bg: 'white',
  borderRadius: 's',
  py: 'm',
  pr: 'xxs',
  color: 'dark',
  states: {
    '&:-webkit-scrollbar-track': {
      background: 'red',
    },
  },
};

export const option = {
  px: 'm',
  height: 44,
  width: '100%',
  justifyContent: 'center',
  border: 0,
  bg: 'white',
  outline: 'none',
  selectedStyle: {
    bg: 'light',
  },
  iconStyle: {
    name: 'checkUnselected',
    color: 'white',
  },
  selectedIconStyle: {
    name: 'checkSelected',
  },
};
