/**
 * @packageDocumentation
 * @module @ariestech/atr-core-i18n
 */
import { createAction } from 'typesafe-actions';

export const initializeTranslations = createAction('i18n/INITIALIZE')();
export const initializeTranslationsSuccess = createAction(
  'i18n/INITIALIZE_SUCCESS',
)<string>();
export const loadLanguage = createAction('i18n/LOAD_LANGUAGE')();
export const changeLanguage = createAction('i18n/CHANGE_LANGUAGE')<string>();
export const changeLanguageRequest = createAction(
  'i18n/CHANGE_LANGUAGE_REQUEST',
)<string>();
