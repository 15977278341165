export const gradients = {
  primary: {
    start: 0,
    angle: 90,
    end: 100,
    colors: ['gradient/start', 'gradient/middle', 'gradient/end'],
    kind: 'linear',
  },
  'primary/hover': {
    start: 0,
    angle: 90,
    end: 100,
    colors: [
      'gradient/primary/hover/start',
      'gradient/primary/hover/middle',
      'gradient/primary/hover/end',
    ],
    kind: 'linear',
  },
  secondary: {
    start: 0,
    end: 100,
    angle: 0,
    colors: ['gradient/end', 'gradient/middle', 'gradient/start'],
    kind: 'linear',
  },
  loading: {
    start: 0,
    end: 100,
    angle: 90,
    colors: ['transparent', 'grey/light', 'transparent'],
    kind: 'linear',
  },
};
