import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslations } from '../../../language/hooks';
import { Flex, Typography } from '@ariestech/atr-core-web-ui';
import { useVideos } from '../../hooks';
import { Homepage } from './../../../homepage/views/Homepage/Homepage';
import { VideoList } from '../../components';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { MainInteraction } from 'src/videoplayer/components/MainInteraction';
import { useEcommerceContext } from './../../../ecommerce/hooks/useEcommerceContext';
import { useVideoContext } from './../../hooks/useVideoContext';
import { LastraPietra } from './../../../castello/components/LastraPietra/LastraPietra';
import { MatchButton } from 'src/castello/components/MatchButton/MatchButton';
import { Farfalle } from 'src/castello/components/Farfalle/Farfalle';
import { Portone } from 'src/castello/components/Portone/Portone';
import { Calpurnia } from './../../../castello/components/Calpurnia/Calpurnia';

export const VideoPlayer = (): JSX.Element => {
  const t = useTranslations();
  const { videoList, useVideoRequest } = useVideos();
  const { execute, loading, error, errorMessage, success } = useVideoRequest();
  const [showVideo, setShowVideo] = useState(false);
  const handle = useFullScreenHandle();
  const { videoctx } = useVideoContext();
  //const { ecommctx } = useEcommerceContext();

  useEffect(() => {
    if (!videoList || !videoList.length) {
      execute({});
    }
    videoctx.customComponents['Calpurnia'] = <Calpurnia />;
    videoctx.customComponents['Portone'] = <Portone />;
    videoctx.customComponents['LastraPietra'] = <LastraPietra />;
    videoctx.customComponents['Farfalle'] = <Farfalle />;
  }, []);

  /*   const reportChange = useCallback(
    (state, handle) => {
      console.log('Screen  went to', state, handle);
      console.log('PIPPO', ecommctx['pippo']);
      //document.fullscreenElement = handle.node;
    },
    [ecommctx['pippo']],
  ); */

  const successOrError = useMemo(() => {
    return (
      <FullScreen handle={handle}>
        {success ? (
          showVideo ? (
            <>
              <VideoList />
              <MainInteraction />
            </>
          ) : (
            <div className='wrap'>
              <em>
                <br />
                <br />
                Il castello Stregato
              </em>
              <h1>E - MOVIE</h1>
              <div className='button-wrapper'>
                <button
                  className='button'
                  onClick={() => {
                    setShowVideo(true);
                    handle.enter();
                  }}
                >
                  <span>Inzia l'avventura</span>
                </button>
              </div>
            </div>
          )
        ) : (
          <Flex>ERROREEE!</Flex>
        )}
      </FullScreen>
    );
  }, [handle, success, showVideo, t]);

  return loading ? <Homepage /> : successOrError;
};
