import { it as homepage } from '../../homepage/locales';
import { it as videoplayer } from '../../videoplayer/locales';
/**
 * import domain locales here (included domain key) and spread it on the global translations object
 */

export const it = {
  ...homepage,
  ...videoplayer,
};
