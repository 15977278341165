/**
 * @packageDocumentation
 * @module @ariestech/atr-core-i18n
 */
import { State as I18nState } from '../reducers';
import { createSelector } from 'reselect';

type State = {
  i18n: I18nState;
};

const selectState = (state: State) => state;

const selectI8nSlice = createSelector(selectState, res => res.i18n);

export const isI18nInitialized = createSelector(
  selectI8nSlice,
  res => res.initialized,
);

export const selectCurrentLanguage = createSelector(
  selectI8nSlice,
  res => res.currentLanguage,
);
