import {
  actions as i18nActions,
  initI18n,
  Priority,
  Config as ConfigI18n,
} from '../../language';
import { Store } from 'redux';
import { it } from '../locales';

const configureI18n = (store: Store) => {
  initI18n({
    defaultLanguage: {
      languageCode: 'it-IT',
      isRTL: false,
      languageTag: 'it',
    },
    localePath: {
      it,
    },
    endPointI18n: '',
    endPointAuthorize: '',
    clientID: '',
    priority: Priority.Locale,
  } as ConfigI18n);

  store.dispatch(i18nActions.initializeTranslations());
};

export default configureI18n;
