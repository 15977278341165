import { createContext } from 'react';

export type ecommerceContextType = {
  iconcartRef: React.RefObject<any>;
  [key: string]: React.RefObject<any>;
};

const ctx: ecommerceContextType = {
  iconcartRef: null,
};

export default createContext(ctx);
