import { Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

let _persistor: Persistor = (null as unknown) as Persistor;

export function setPersistor(persistor: Persistor) {
  _persistor = persistor;
}

export function getPersistor() {
  return _persistor;
}

export const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['requestStatus', 'i18n', 'network'],
};
