import { Saga, Task } from 'redux-saga';

const injectedSagas: { [key: string]: any } = {};

let sagaRunner: <S extends Saga>(saga: S, ...args: Parameters<S>) => Task;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function setSagaRunner<S>(
  runner: <S extends Saga>(saga: S, ...args: Parameters<S>) => Task,
) {
  sagaRunner = runner;
}

/**
 *
 * Use this function in lazy loaded screen to inject new sagas into the middleware.
 * You do not need to put the saga inside the root sagas list.
 * !IMPORTANT
 * If you lazy load the sagas you need to be sure that is is loaded before dispatcing any action that you want to be intercepted
 *
 * If the key has already been declared the injection is skipped so it is safe to force the same sagas injection in any point
 *
 * @param key functionality name
 * @param saga functionality root saga
 */
export function injectSaga(key: string, saga: Saga) {
  if (key in injectedSagas) {
    return;
  }

  const task = sagaRunner(saga);

  injectedSagas[key] = task;
}
