import { createReducer } from 'typesafe-actions';
import { State, FetchVideos } from '../types';
import * as actionsVideos from '../actions';
import * as fetchCases from './fetch';

const defaultState: State = {
  videos: [],
};

export const rootReducer = createReducer<State, FetchVideos>(defaultState)
  .handleAction(actionsVideos.videosRequest, fetchCases.fetchVideosRequestCase)
  .handleAction(
    actionsVideos.videosRequestSuccess,
    fetchCases.fetchVideosSuccessCase,
  )
  .handleAction(
    actionsVideos.videosRequestFailure,
    fetchCases.fetchVideosFailureCase,
  );
