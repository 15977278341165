export const chip = {
  p: 'l',
  height: 40,
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  borderRadius: 'round',
  iconStyle: {
    color: 'white',
  },
  display: 'flex',
  states: {
    '& > p': {
      margin: 0,
      display: 'inherit',
    },
  },
  variants: {
    primary: {
      bg: 'positive',
      color: 'white',
      borderWidth: 'xs',
      borderColor: 'positive',
      iconStyle: {
        width: 30,
        color: 'white',
      },
      textStyle: {
        variant: 'p2',
        color: 'white',
      },
      selectedStyle: {
        bg: 'transparent',
        color: 'positive',
      },
    },
    secondary: {
      bg: 'transparent',
      iconStyle: {
        width: 30,
      },
      textStyle: {
        variant: 'p1',
        color: 'positive',
      },
      selectedStyle: {
        bg: 'positive',
        borderWidth: 'xs',
        borderColor: 'positive',
        color: 'white',
      },
    },
  },
};
