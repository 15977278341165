import { useEcommerceContext } from 'src/ecommerce/hooks/useEcommerceContext';

export const useCartPosition = () => {
  const { ecommctx } = useEcommerceContext();

  return {
    X: ecommctx?.iconcartRef?.current?.el?.offsetParent?.offsetLeft,
    Y: ecommctx?.iconcartRef?.current?.el?.offsetParent?.offsetTop,
  };
};
