import React, { useEffect, useState } from 'react';
import { useNavigateVideo } from 'src/videoplayer/hooks';

type Props = {
  password: string;
  navigateto: string;
  hint: string;
};

export const EnterPassword = ({
  password,
  navigateto,
  hint,
}: Props): JSX.Element => {
  const { useNavigateVideoRequest } = useNavigateVideo();
  const { execute } = useNavigateVideoRequest();

  const [testo, setTesto] = useState('');
  const [visibile, setvisibile] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTesto(e.target.value.toUpperCase());
  };

  useEffect(() => {
    if (testo.toUpperCase() === password.toUpperCase()) {
      setvisibile(true);
    }
  }, [testo]);

  const goOn = () => {
    execute(navigateto);
  };

  return (
    <div className='lastrainput'>
      <h4>{hint}</h4>
      <input
        type='text'
        placeholder='..... scrivi la soluzione'
        readOnly={visibile}
        onChange={handleChange}
      ></input>

      {visibile ? (
        <button
          className='clickable buttonlastra'
          onClick={() => {
            goOn();
          }}
        >
          ESATTO! Clicca per proseguire
        </button>
      ) : null}
    </div>
  );
};
