import React, { useEffect } from 'react';
import { useTranslations } from '../../../language';
import { Flex } from '@ariestech/atr-core-web-ui';
import { useNavigateVideo, useVideos } from '../../hooks';
import { SingleVideo } from './../SingleVideo/SingleVideo';
import { useWindowSize } from 'src/_shared/hooks';
import { useTransition, config, animated } from 'react-spring';
import { useCartPosition } from './../../../ecommerce/hooks/useCartPosition';

export const VideoList = (): JSX.Element => {
  // const t = useTranslations();
  const { currentVideo } = useNavigateVideo();
  const [width, height] = useWindowSize();
  const { videoList, useVideoRequest } = useVideos();

  const { X, Y } = useCartPosition();

  useEffect(() => {
    console.log({ X, Y });
    //execute('{}');
  }, [X, Y]);

  const transitions = useTransition(currentVideo, item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  });

  const prefetchAndRender = () => {
    return transitions.map(({ item, props, key }) => (
      <animated.div className='fulldiv' key={key} style={{ ...props }}>
        <SingleVideo key={item.id} video={item} />
      </animated.div>
    ));
  };

  return (
    <Flex className='fulldiv' height={height}>
      {prefetchAndRender()}
    </Flex>
  );
};
