import like from './like/like.png';
import search from './search/search.png';
import redirect from './redirect/redirect.png';
import user from './user/user.png';
import doctor from './doctor/doctor.png';
import family from './family/family.png';
import money from './money/money.png';
import check from './check/check.png';
import slider from './slider/slider.png';

export const icons = {
  like,
  check,
  search,
  redirect,
  user,
  doctor,
  family,
  money,
  slider,
};
