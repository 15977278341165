import {
  card,
  input,
  spacer,
  button,
  checkbox,
  textInput,
  typography,
  animatedShape,
  switchStyle,
  radio,
  chip,
  grid,
  formInput,
  loader,
  option,
  dropDown,
  dropDownHeader,
  dropDownOptionsContainer,
} from './components';
import { colors } from './colors';
import { gradients } from './gradients';
import { fontFamilies, fontSizes, lineHeights } from './fonts';
import { keyframes, animations } from './animations';
import { radii, borderWidths } from './borders';
import { shadows } from './shadows';

export const theme = {
  keyframes,
  animations,
  colors,
  gradients,
  fonts: fontFamilies,
  lineHeights,
  fontSizes,
  radii,
  borderWidths,
  shadows,
  space: {
    none: '0px',
    xxs: '4px',
    xs: '8px',
    s: '16px',
    m: '20px',
    l: '24px',
    xl: '32px',
    xxl: '40px',
  },
  sizes: {
    none: '0px',
    xxs: '4px',
    xs: '8px',
    s: '16px',
    m: '20px',
    l: '24px',
    xl: '32px',
    xxl: '40px',
  },
  breakpoints: ['321px', '415px', '835px', '1000px', '1200px'],
  components: {
    Card: card,
    Input: input,
    Spacer: spacer,
    Button: button,
    Checkbox: checkbox,
    TextInput: textInput,
    Typography: typography,
    AnimatedShape: animatedShape,
    CheckboxEffects: checkbox,
    Switch: { ...switchStyle },
    Radio: { ...radio },
    Chip: { ...chip },
    Grid: { ...grid },
    FormInput: { ...formInput },
    Loader: { ...loader },
    Option: option,
    DropDown: dropDown,
    DropDownHeader: dropDownHeader,
    DropDownOptionsContainer: dropDownOptionsContainer,
  },
};
