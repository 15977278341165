import { ActionHandler } from 'src/videoplayer/store';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Button } from '../';
import React from 'react';
import { useVideoContext } from './../../hooks/useVideoContext';

export type ActionHandlersRedererProps = {
  handler: ActionHandler;
  currentTime: number;
  widthRatio: number;
};

export const ActionHandlersRederer = ({
  handler,
  currentTime,
  widthRatio,
}: ActionHandlersRedererProps): JSX.Element => {
  const [time, setTime] = useState(0);
  const { videoctx } = useVideoContext();

  useEffect(() => {
    setTime(0);

    return () => {
      setTime(0);
    };
  }, []);

  useEffect(() => {
    setTime(currentTime);
  }, [currentTime]);

  const itemsVisibles = useMemo(() => {
    let visible =
      time >= handler.fromTime &&
      (handler.toTime === -1 || time <= handler.toTime);
    return visible;
  }, [time, handler.fromTime, handler.toTime]);

  const renderHandlers = useMemo(() => {
    return handler.contents?.map(element => {
      switch (element.contentType) {
        case 'Button':
          //console.log('BUTTON');
          return (
            <Button
              key={element.actionId}
              widthRatio={widthRatio}
              content={element}
              style={{
                display: itemsVisibles && element.show ? 'block' : 'none',
              }}
            ></Button>
          );

        case 'CustomComponent':
          return (
            <div
              className='fulldiv'
              key={element.actionId}
              style={{
                display: itemsVisibles && element.show ? 'flex' : 'none',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {videoctx.customComponents[element.contentIdToShow]}
            </div>
          );
        default:
          return <div></div>;
      }
    });
  }, [itemsVisibles, widthRatio]);

  return <>{renderHandlers}</>;
};
