import {
  State,
  FetchVideosRequest,
  FetchVideosRequestSuccess,
  FetchVideosRequestFailure,
} from '../types';

export const fetchVideosRequestCase = (
  state: State,
  action: FetchVideosRequest,
): State => ({
  ...state,
  rollbackData: {
    ...state.videos,
  },
});
export const fetchVideosSuccessCase = (
  state: State,
  action: FetchVideosRequestSuccess,
): State => ({
  ...state,
  rollbackData: undefined,
  videos: action.payload,
});

export const fetchVideosFailureCase = (
  state: State,
  action: FetchVideosRequestFailure,
): State => ({
  ...state,
  videos: { ...state.rollbackData },
  rollbackData: undefined,
});
