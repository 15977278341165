import { createContext } from 'react';

export type customFunction = {
  [key: string]: () => {};
};

export type customHandler = {
  [key: string]: any;
};

export type videoContextType = {
  functions: customFunction[];
  customComponents: customHandler[];
};

const ctx: videoContextType = {
  functions: [],
  customComponents: [],
};

export default createContext(ctx);
